<template>
  <div class="signin">
    <b-row align-v="center" align-h="end">
      <b-col md="5">
        <b-card>
          <b-link @click="toggleLogin" class="toggle-login" v-b-tooltip.hover
                  :title="curLogin === 'mobile' ? '切换微信扫码登录' : '切换账号密码登录'">
            <fa icon="qrcode" v-if="curLogin === 'mobile'"/>
            <fa icon="keyboard" v-else/>
          </b-link>

          <div class="login-qrcode" v-if="curLogin === 'qrcode'">
            <template v-if="isWechat">
              <div class="title">
                <fa icon="weixin" fab class="text-success"/>
                微信登录
              </div>

              <div class="btn-area" style="margin-top: 100px;">
                <b-btn block variant="success" :href="authUrl">
                  <fa icon="loading" v-if="autoLogin"/>
                  <fa icon="lock" v-else/>
                  {{autoLogin ? '正在登录' : '点击授权登录'}}
                </b-btn>
              </div>
            </template>
            <template v-else>
              <div class="title">
                <fa icon="weixin"/>
                微信扫码，安全登录
              </div>
              <div class="py-5 text-center">
                <qrcode :src="qrcodeUrl" raw size="140px">
                  <div class="mask" v-if="qrcodeExpired">
                    <b-btn @click="refreshQrcode" variant="primary">
                      <fa icon="sync"/>
                      刷新
                    </b-btn>
                  </div>
                </qrcode>
                <div class="mt-2">
                  <template v-if="isWechat">长按识别二维码</template>
                  <template v-else-if="qrcodeExpired">二维码已失效<br>点击上方按钮刷新</template>
                  <template v-else>
                    <div>
                      打开微信
                      <img src="@/assets/images/icons/scan.svg" class="icon"
                           style="height: 1.25em;" alt="">
                      扫一扫
                    </div>
                    <div class="small">若您未关注公众号，扫码后请点击关注</div>
                  </template>
                </div>
              </div>
            </template>
          </div>

          <div class="login-form" v-else>
            <div class="title mb-3">
              <fa icon="key"/>
              账号密码登录
            </div>

            <form @submit.prevent="login" class="mt-3">
              <b-form-group label="手机号">
                <b-input maxlength="13" placeholder="请输入手机号" required
                         :autofocus="!loginForm.phone"
                         type="tel" v-model="loginForm.phone"/>
              </b-form-group>
              <b-form-group label="密码">
                <b-input maxlength="30" placeholder="请输入登录密码" required
                         :autofocus="!!loginForm.phone"
                         type="password" v-model="loginForm.password"/>
              </b-form-group>
              <p>
                <b-btn block disabled v-if="logging" variant="primary">正在登录</b-btn>
                <b-btn :disabled="!loginForm.phone || !loginForm.password" block
                       type="submit" v-else variant="primary">
                  登录
                </b-btn>
              </p>
            </form>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import Qrcode from '@/components/QRCode'
import { getAuthUrl } from '@/utils/utils'

export default {
  name: 'signin',
  title: '登录微信书',
  components: {Qrcode},
  data() {
    return {
      loginForm: {
        phone: '',
        password: ''
      },
      autoLogin: this.$route.query.auto !== undefined,
      qrcodeUrl: '',
      qrcode: {},
      timeCount: 0,
      qrcodeExpired: false,
      curLogin: 'qrcode',

      error: '',
      redirectUrl: this.$route.query.redirect || '',
      authUrl: getAuthUrl(this.$route.query.redirect || '/books'),
      logging: false,
      checking: false
    }
  },
  created() {
    if (this.hasLogin) {
      return this.onLogin()
    }

    if (window.isWechat && this.autoLogin) {
      // 在微信中且 url 参数中有 auto
      // 则自动进行微信授权
      window.top.location.replace(this.authUrl)
      this.$gtag.event('login', {method: 'official_account'})
      return
    }

    this.getQrcode()
    const phone = this.$ls.get('loginPhone')
    if (phone) {
      this.loginForm.phone = phone
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  computed: {
    errMsg() {
      if (this.error) {
        return this.error
      }

      const phoneRegex = /^(1[34578])\d{9}$/

      if (!this.loginForm.phone) {
        return '请输入手机号码'
      }
      if (!this.loginForm.password) {
        return '请输入密码'
      }
      if (!phoneRegex.test(this.loginForm.phone)) {
        return '手机号码格式错误'
      }
      if (this.loginForm.password.length < 6) {
        return '登录密码不应少于6位'
      }

      return ''
    }
  },
  methods: {
    // 手机号密码登录
    async login() {
      this.error = ''
      if (this.errMsg) {
        this.$alert.error(this.errMsg)
        return
      }
      try {
        this.logging = true
        const result = await this.$req.post('/api/user/vip/login', {
          loginPhone: this.loginForm.phone,
          password: this.loginForm.password
        })
        this.$ls.set('loginPhone', this.loginForm.phone)
        this.onLogin(result)
        this.$gtag.event('login', {method: 'password'})
      } finally {
        this.logging = false
      }
    },
    // 登录成功
    async onLogin({authToken, expireIn} = {}) {
      clearInterval(this.interval)

      if (authToken) {
        this.$store.commit('setToken', {authToken, expireIn})
        const user = await this.$ajax.fetchUserInfo()
        this.$store.commit('setUser', user)
      }

      const redirectUrl = this.$route.query.redirect

      if (redirectUrl) {
        if (/^\//.test(redirectUrl)) {
          this.$router.replace(redirectUrl)
        } else {
          location.replace(redirectUrl)
        }
        return
      }

      if (this.user.isAgent && !window.isDev) {
        location.replace('/agent')
        return
      }

      this.$router.replace('/books')
    },
    // 获取二维码
    async getQrcode() {
      const result = await this.$req.get('/api/qrcode')
      this.qrcodeUrl = result.qrcodeUrl
      this.qrcode = result
      this.checkTicket()
    },
    // 验证 ticket 是否使用
    checkTicket() {
      let checkTimes = 0
      const maxTimes = 50
      this.checking = false
      clearInterval(this.interval)
      this.interval = setInterval(async () => {
        if (this.checking) {
          return
        }
        this.checking = true
        // 超时，二维码过期
        if (checkTimes > maxTimes) {
          clearInterval(this.interval)
          this.qrcodeExpired = true
          return
        }
        if (this.curLogin !== 'qrcode') {
          clearInterval(this.interval)
          return
        }
        checkTimes++
        const ticket = this.qrcode.ticket
        try {
          const result = await this.$req.get('/api/qrcode/check', {
            params: {
              ticket,
              t: Date.now()
            }
          })
          if (result.scan) {
            this.onLogin(result)
            this.$gtag.event('login', {method: 'qrcode'})
          }
        } finally {
          this.checking = false
        }
      }, 2000)
    },
    // 切换登录方式
    toggleLogin() {
      this.curLogin = this.curLogin === 'mobile' ? 'qrcode' : 'mobile'
      if (this.curLogin === 'qrcode') {
        this.checkTicket()
      }
    },
    refreshQrcode() {
      this.getQrcode().then(() => {
        this.qrcodeExpired = false
      })
    }
  }
}
</script>

<style lang="scss">
body[data-page="signin"] {
  main {
    padding-bottom: 0;
  }
}
</style>

<style scoped lang="scss">
.signin {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-image: url('../assets/images/banners/signin.jpg');
  background-size: cover;
  background-position: center;
  min-height: 400px;

  > .row {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    height: 100%;
  }

  .card {
    max-width: 320px;
    height: 320px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: $box-shadow;
    border-color: transparent;
  }

  .toggle-login {
    $size: 42px;
    $sqrt2: 1.414;

    text-decoration: none;
    z-index: 10;
    text-align: right;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    overflow: hidden;
    padding-top: $size * .15;
    padding-right: $size * .2;

    &:after {
      $deviation: 0.4;
      background-color: #fff;
      position: absolute;
      content: '';
      width: $size * $sqrt2 * (1 + $deviation);
      height: $size * $sqrt2 * (1 + $deviation);
      right: (1 - $sqrt2) * $size * (1 + $deviation);
      top: 0;
      transform: rotate(45deg);
      transform-origin: top left;
      pointer-events: none;
    }

    i {
      display: block;
      font-size: $size * .8;
      width: 1em;
      height: 1em;
      line-height: 1;
      color: $primary;

      &.fa-qrcode {
        font-size: $size * 1.1;
      }

      &.fa-desktop {
        margin-top: $size * .1;
        margin-right: $size * .05;
      }
    }
  }

  .login-qrcode, .login-form {
    .title {
      font-size: 1.2rem;
    }
  }

  .login-qrcode {
    .update-qrcode {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 140px;
      background: rgba(255, 255, 255, .95);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .alert {
    margin-bottom: 0;
  }
}
</style>
